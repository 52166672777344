import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { TChemicalState } from '../../types/Chemical';
import Image from '../cms/Image';
import { useTranslation } from 'react-i18next';
import FirstAidMeasurements from './panels/FirstAidMeasurements';
import Hazards from './panels/Hazards';
import Company from './panels/Company';
import RevisedDate from './panels/RevisedDate';
import { simpleDateFormat } from '../../Helpers';
import HandlingAndStorage from './panels/HandlingAndStorage';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ProtectiveMeasurements from './panels/ProtectiveMeasurements';
import { fetchWithAuthentication } from '../../actions/BaseAction';
import Exposure from './panels/Exposure';
import FireFighting from './panels/FireFighting';
import Spills from './panels/Spills';
import { useMediaQuery, useTheme } from '@material-ui/core';

interface IChemicalProductProps {
  chemical: TChemicalState;
  expandedFirstAid: boolean;
  expandedHazards: boolean;
  expandedProducer: boolean;
  expandedRevised: boolean;
  expandedHandling: boolean;
  expandedExposure: boolean;
  expandedFireFighting: boolean;
  expandedSpills: boolean;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const ChemicalProduct: React.FC<IChemicalProductProps> = ({
  chemical,
  expandedHazards,
  expandedProducer,
  expandedFirstAid,
  expandedRevised,
  expandedExposure,
  expandedFireFighting,
  expandedSpills,
  handleChange,
  expandedHandling,
}): JSX.Element => {
  const { t } = useTranslation();
  const [safetyDataSheet, setsafetyDataSheet] = useState('*');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (chemical.activeChemical?.data.safetyDataSheet) {
      fetchWithAuthentication(chemical.activeChemical?.data.safetyDataSheet)
        .then(blob => {
          if (blob !== null) {
            setsafetyDataSheet(URL.createObjectURL(blob));
          }
        })
        .catch(error => {throw error});
    }
  }, [chemical.activeChemical]);

  const getRevisedDate = (date: Date) => {
    return simpleDateFormat(date);
  };

  return (
    <>
      {chemical && (
        <>
          {chemical.activeChemical?.data.image ? (
            <div style={{ width: isXs ? '100%' : '35%'}}>
              <Image
                image={{
                  url: chemical?.activeChemical?.data.image as string,
                  name: chemical?.activeChemical?.data.name as string,
                }}
              />
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <i
                className='fas fa-flask'
                style={{ fontSize: '150px', color: 'lightgray' }}
              />
            </div>
          )}

          {chemical.activeChemical && (
            <ProtectiveMeasurements
              text={chemical.activeChemical.data.protectiveEquipment as string}
              ppe={chemical.activeChemical.ppe}
            />
          )}

          {((chemical.activeChemical?.hazards &&
            chemical.activeChemical.hazards.length > 0) ||
            chemical?.activeChemical?.data.signalWordType ||
            chemical?.activeChemical?.data.hazardStatements ||
            chemical?.activeChemical?.data.precautionaryStatements ||
            chemical?.activeChemical?.data.otherHazards
          ) && (
              <Hazards
                hazards={chemical.activeChemical.hazards}
                signalWordType={chemical?.activeChemical?.data.signalWordType}
                hazardStatements={chemical?.activeChemical?.data.hazardStatements}
                precautionaryStatements={chemical?.activeChemical?.data.precautionaryStatements}
                otherHazards={chemical?.activeChemical?.data.otherHazards}
                expanded={expandedHazards}
                handleChange={handleChange}
              />
            )}

          {(
            chemical.activeChemical?.data.controlParameters ||
            chemical.activeChemical?.data.protectiveEquipment
          ) && (
            <Exposure
              expanded={expandedExposure}
              controlParameters={chemical.activeChemical?.data.controlParameters}
              protectiveEquipment={chemical.activeChemical?.data.protectiveEquipment}
              handleChange={handleChange}
            />
          )}

          {(
            chemical.activeChemical?.data.firstAidMeasures ||
            chemical.activeChemical?.data.emergencyInformation ||
            chemical.activeChemical?.data.symptomsOrEffects ||
            chemical.activeChemical?.data.recommendations
          ) && (
            <FirstAidMeasurements
              expanded={expandedFirstAid}
              firstAidMeasures={chemical.activeChemical?.data.firstAidMeasures}
              emergencyInformation={chemical.activeChemical?.data.emergencyInformation}
              symptomsOrEffects={chemical.activeChemical?.data.symptomsOrEffects}
              recommendations={chemical.activeChemical?.data.recommendations}
              handleChange={handleChange}
            />
          )}

          {(
            chemical.activeChemical?.data.extinguishingMedia ||
            chemical.activeChemical?.data.specialFireHazards ||
            chemical.activeChemical?.data.adviceForFirefighters
          ) && (
            <FireFighting
              expanded={expandedFireFighting}
              extinguishingMedia={chemical.activeChemical?.data.extinguishingMedia}
              specialFireHazards={chemical.activeChemical?.data.specialFireHazards}
              adviceForFirefighters={chemical.activeChemical?.data.adviceForFirefighters}
              handleChange={handleChange}
            />
          )}

          {(
            chemical.activeChemical?.data.personalPrecautions ||
            chemical.activeChemical?.data.environmentalPrecautions ||
            chemical.activeChemical?.data.containmentAndCleanUp
          ) && (
            <Spills
              expanded={expandedSpills}
              personalPrecautions={chemical.activeChemical?.data.personalPrecautions}
              environmentalPrecautions={chemical.activeChemical?.data.environmentalPrecautions}
              containmentAndCleanUp={chemical.activeChemical?.data.containmentAndCleanUp}
              handleChange={handleChange}
            />
          )}

          {(
            chemical.activeChemical?.data.handlingAndStorage ||
            chemical.activeChemical?.data.storage ||
            chemical.activeChemical?.data.disposalConsiderations
          ) && (
            <HandlingAndStorage
              expanded={expandedHandling}
              handlingAndStorage={chemical.activeChemical?.data.handlingAndStorage}
              storage={chemical.activeChemical?.data.storage}
              disposalConsiderations={chemical.activeChemical?.data.disposalConsiderations}
              handleChange={handleChange}
            />
          )}

          {(chemical?.activeChemical?.data.producer ||
            chemical?.activeChemical?.data.vendor) && (
            <Company
              title={t('chemicals.vendor_information')}
              producer={chemical.activeChemical.data.producer}
              vendor={chemical.activeChemical.data.vendor}
              expanded={expandedProducer}
              handleChange={handleChange}
              panelId={4}
            />
          )}

          {chemical.activeChemical?.data.revisedDate && (
            <RevisedDate
              bodyText={
                getRevisedDate(
                  chemical.activeChemical.data.revisedDate
                ) as string
              }
              expanded={expandedRevised}
              handleChange={handleChange}
            />
          )}

          {chemical.activeChemical?.data.safetyDataSheet && (
            <Grid container justifyContent="space-around">
              <Grid item xs={6}>
                <Button
                  type='button'
                  fullWidth
                  variant='contained'
                  color='primary'
                  target={'_blank'}
                  href={safetyDataSheet}
                  download
                >
                  {t('chemical.download.sds')}
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default ChemicalProduct;
